<template>
  <div class="container">
    <navbar></navbar>
    <div class="content py-20">
      <van-row class="t-center d-flex ai-center">
        <van-col span="8"></van-col>
        <van-col span="8" class="bold fs-xxl"
          >GameFi {{ $t("home.market") }}</van-col
        >
        <van-col span="8"
          ><van-button
            color="#E67B06"
            size="mini"
            class="purchase"
            @click="toRecord"
            >{{ $t("market.history") }}</van-button
          ></van-col
        >
      </van-row>
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :pulling-text="$t('public.pulling')"
        :loosing-text="$t('public.loosing')"
        :loading-text="$t('public.loading')"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('public.nomores')"
          :loading-text="$t('public.loading')"
          :offset="100"
          @load="onLoad"
        >
          <div class="d-flex flex-wrap jc-between px-40">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="list_item m-t-15"
              @click="toDetail(item)"
            >
              <van-image
                :src="item.remote_url"
                fit="cover"
                width="auto"
                height="100%"
              />
              <div class="tip t-center fs-lg bold w-100">
                <p>{{ lang == "zh" ? item.name : item.name_en }}</p>
              </div>
              <van-image
                v-if="item.type.name_en == 'Discount'"
                :src="
                  lang == 'zh'
                    ? require('@/assets/discount_zh.png')
                    : require('@/assets/discount_en.png')
                "
                width="40px"
                height="40px"
                class="icon"
              />
              <van-image
                v-else-if="item.type.name_en == 'PreSell'"
                :src="
                  lang == 'zh'
                    ? require('@/assets/prediction_zh.png')
                    : require('@/assets/prediction_en.png')
                "
                width="40px"
                height="40px"
                class="icon"
              />
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      totalPage: 1,
      pageNumber: 1,
      list: [],
    };
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
  },
  mounted() {
    // this.init();
  },
  methods: {
    //页面初始化之后会触发一次，在页面往下加载的过程中会多次调用【上拉加载】
    onLoad() {
      setTimeout(() => {
        this.finished = false;
        this.getList();
        this.pageNumber++;
      }, 500);
    },
    init() {
      this.totalPage = 1;
      this.pageNumber = 1;
      this.getList();
    },
    onRefresh() {
      setTimeout(() => {
        this.totalPage = 1;
        this.pageNumber = 1;
        this.getList();
      }, 1000);
    },
    getList() {
      let that = this;
      this.loading = true;
      this.$axios
        .get(this.$api.gamefilist, {
          page: this.pageNumber,
          page_size: 8,
        })
        .then((res) => {
          if (res.code == 200) {
            that.$toast.clear();
             that.loading = false;
            that.refreshing = false;
            let arr = res.data.list;
            that.totalPage = res.data.totalPage; // 总条数
            if (arr == null || arr.length === 0) {
              // 加载结束
              that.finished = true;
              return;
            }
            that.list.push(...arr);
            // 如果当前页数>=总条数，不再触发滚动加载
            if (that.pageNumber >= that.totalPage) {
              that.finished = true;
            }
          } else {
            this.loading = false;
            that.$toast(res.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //游戏详情
    toDetail(item) {
      this.$router.push("/gameDetail?id=" + item.id);
    },
    //购买记录
    toRecord() {
      this.$router.push("/buyRecord");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-grid-item__content {
  background-color: transparent;
  padding: 0 !important;
}
.purchase {
  border-radius: 5px;
}
.list_item {
  width: 47%;
  height: 200px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  .tip {
    line-height: 30px;
    background-color: rgba($color: #0e2648, $alpha: 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }
  .icon_text {
    font-size: 7px;
    position: absolute;
    top: 10px;
    left: 4px;
    transform: rotate(-45deg);
  }
  .icon_text2 {
    top: 10px;
    left: 0;
  }
}
</style>